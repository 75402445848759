<template>
  <div class="change-password-page">
    <van-nav-bar
      title="修改密码"
      left-arrow
      safe-area-inset-top
      placeholder
      @click-left="onClickLeft"
      :style="{'padding-top': statusBarHeight + 'px'}"
    />
    <van-cell-group>
      <van-form @submit="onSubmit">
        <div class="change-password-box">
          <van-field
            v-model="oldPassword"
            type="password"
            name="原始密码"
            label="原始密码"
            placeholder="请输入原始密码"
            :rules="[{ required: true, message: '请输入原始密码' }]"
          />
        </div>
        <div class="change-password-box">
          <van-field
            v-model="newPassword"
            type="password"
            name="新密码"
            label="新密码"
            placeholder="请输入新密码"
            :rules="[{ required: true, message: '请输入新密码' }, { validator, message: '密码必须至少6位并且包含大小写字母和数字' }]"
          />
        </div>
        <div class="change-password-box">
          <van-field
            v-model="retypePassword"
            type="password"
            name="确认密码"
            label="确认密码"
            placeholder="请再次输入新密码确认"
            :rules="[{ required: true, message: '请再次输入新密码确认' }, { validator, message: '密码必须至少6位并且包含大小写字母和数字' }, { validator: compareToFirstPassword, message: '两次输入的密码不一致' }]"
          />
        </div>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit" :loading="loginBtn">保存</van-button>
        </div>
      </van-form>

    </van-cell-group>
  </div>
</template>
<script>
import { Toast } from 'vant'
import { mapState } from 'vuex'
export default {
  name: 'ChangePassword',
  components: {},
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      retypePassword: '',
      loginBtn: false
    }
  },
  computed: {
    ...mapState({
      statusBarHeight: state => state.global.statusBarHeight
    })
  },
  methods: {
    validator (val) {
      return /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,30})$/.test(val)
    },
    compareToFirstPassword(val) {
      console.log('new password: ' + this.newPassword)
      if (val && val !== this.newPassword) {
        return false
      } else {
        return true
      }
    },
    onSubmit() {
      console.log('submit')
      const that = this
      that.loginBtn = true
      const params = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      }
      this.$api.security.changePassword(params).then(res => {
        Toast.success('修改密码成功！')
        that.loginBtn = false
        this.$router.push({ name: 'SecuritySetting' })
      }).catch(error => {
        that.loginBtn = false
        let errMsg = '修改密码失败！'
        if (error.response) {
          errMsg = error.response.data.message
        }
        Toast.fail(errMsg)
      })
    },
    onClickLeft () {
      // this.$router.push({ name: 'SecuritySetting' })
      window.history.go(-1)
    }
  },
  mounted () {
  }
}
</script>
<style lang="less">
.change-password-page {
  background-color: #f8f8f8;
  height: 100vh;
  background-image: url("~@/assets/background.png");
  background-size: 100% 40%;
  background-repeat: no-repeat;
  .van-nav-bar {
    background-color: transparent;
    &::after {
      border-bottom-width: 0
    }
    .van-nav-bar__content {
      height: 40px;
      .van-nav-bar__title {
        font-size: 19px;
        font-weight: bold;
        color: #ffffff;
        font-family: PingFang SC;
      }
      .van-nav-bar__left {
        .van-icon-arrow-left {
          color: #000000;
          font-weight: bold;
          color: #ffffff;
          &::before {
            vertical-align: middle;
          }
        }
      }
    }
  }
  .van-cell-group {
    background-color: transparent;
    overflow: auto;
    padding-top: 15px;
    .change-password-box {
      margin: 0 15px 15px 15px;
      background-color: #ffffff;
      border-radius: 5px;
      .van-cell {
        border-radius: 5px;
        .van-cell__title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          .van-cell__label {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #878787;
            line-height: 28px;
          }
        }
        .dashboard-cell-icon {
          .van-icon {
            width: 20px;
            height: 20px;
            background-color: #37acdf14;
            border-radius: 50%;
            text-align: center;

            &::before {
              color: #319DE2;
              vertical-align: middle;
            }
          }
        }
      }
    }
    &::after {
      border-top-width: 0
    }
  }
}
</style>
